import Link from 'components/link/link';
import Image from 'next/image';
import { getSlug, getStrapiMedia } from 'utils';

interface ProjectsItemProps {
  data: any;
  index: number;
}

interface BadgeProp {
  Title: string;
}

const ProjectsItem: React.FC<ProjectsItemProps> = ({ data, index }) => {
  const attrs = data.project?.data?.attributes;

  if (!attrs) {
    return null;
  }

  return (
    <div className="project-item">
      <Link
        href={`/client/${getSlug(attrs)}`}
        OpenInNewTab={attrs.OpenInNewTab}
        className="project-item__inner"
      >
        <Image
          alt=""
          src={getStrapiMedia(attrs.Image)}
          priority={index <= 2}
          layout="fill"
          style={{
            objectFit: 'cover',
          }}
        />
        <div className="project-item__text">
          <h6>{attrs.Title}</h6>
          <p>{attrs.Subtitle}</p>
        </div>
        {attrs.Badge && (
          <div className="project-item__badge">
            {attrs.Badge.map((item: BadgeProp, index: number) => (
              <div key={index} className="project-item__badge-inner">
                <span>{item.Title}</span>
              </div>
            ))}
          </div>
        )}
      </Link>
    </div>
  );
};

export default ProjectsItem;
